import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import Suspense from './suspense/Suspense';

const App = lazy(() => import('./App'));
const Footer = lazy(() => import('./footer/Footer'));

ReactDOM.render(
  <Suspense>
    <App />
  </Suspense>,
  document.getElementById('app')
);

ReactDOM.render(
  <Suspense>
    <Footer />
  </Suspense>,
  document.getElementById('footer')
);
